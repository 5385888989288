import React, { useState, useEffect } from "react";
import firebase, { firestore, auth } from "../../utils/firebase";
import { useHistory } from "react-router-dom";

const AddressList = () => {
  const [addresses, setAddresses] = useState([]);
  const [searchPhone, setSearchPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const cafe = localStorage.getItem("name");
  const history = useHistory();
  const [editForm, setEditForm] = useState({
    title: "",
    address: "",
  });
  const [newAddress, setNewAddress] = useState({
    phone: "",
    title: "",
    address: "",
  });

  // Function to fetch all addresses
  const fetchAllAddresses = () => {
    setLoading(true);
    setError(null);

    firestore
      .collection(`Restaurants/${cafe}/users`)
      .get()
      .then((querySnapshot) => {
        const addressList = querySnapshot.docs.map((doc) => {
          return doc.data().addressList?.map((addr) => ({
            id: doc.id,
            title: addr.title,
            address: addr.address,
          }));
        });
        const flatAddressList = addressList.flat().filter(Boolean);
        setAddresses(flatAddressList);
        setLoading(false);
      })
      .catch((err) => {
        setError("Adresleri alırken hata oluştu: " + err.message);
        console.error("Error fetching addresses:", err);
        setLoading(false);
      });
  };
  const handleSearch = () => {
    if (!searchPhone) {
      fetchAllAddresses(); // If search input is empty, show all addresses
      return;
    }

    setLoading(true);
    setError(null);

    firestore
      .collection(`Restaurants/${cafe}/users`)
      .doc(searchPhone)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          const addressList = userData.addressList || [];
          const formattedAddresses = addressList.map((addr) => ({
            id: doc.id,
            title: addr.title,
            address: addr.address,
          }));
          setAddresses(formattedAddresses);
        } else {
          setAddresses([]);
          setError("Bu telefon numarasına ait adres bulunamadı.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Adresleri ararken hata oluştu: " + err.message);
        console.error("Error searching addresses:", err);
        setLoading(false);
      });
  };
  const handleAddAddress = () => {
    if (!newAddress.phone) {
      setError("Telefon numarası gereklidir");
      return;
    }
    setLoading(true);

    firestore
      .collection(`Restaurants/${cafe}/users`)
      .doc(newAddress.phone) // Use phone as document ID
      .get()
      .then((doc) => {
        let addressList = doc.exists ? doc.data().addressList || [] : [];
        addressList.push({
          title: newAddress.title,
          address: newAddress.address,
        });

        return firestore
          .collection(`Restaurants/${cafe}/users`)
          .doc(newAddress.phone)
          .set(
            {
              addressList: addressList,
              createdAt: new Date(),
            },
            { merge: true }
          );
      })
      .then(() => {
        fetchAllAddresses();
        setShowAddModal(false);
        setNewAddress({ phone: "", title: "", address: "" });
        setError(null);
      })
      .catch((err) => {
        setError("Adres eklerken hata oluştu: " + err.message);
        console.error("Error adding address:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (phone, addressTitle) => {
    const confirmDelete = window.confirm(
      `"${addressTitle}" adlı adresi silmek istediğinize emin misiniz?`
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }

    setLoading(true);
    setError(null);

    firestore
      .collection(`Restaurants/${cafe}/users`)
      .doc(phone)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          const addressList = userData.addressList || [];

          // Filter out the address to be deleted
          const updatedAddressList = addressList.filter(
            (addr) => addr.title !== addressTitle
          );

          // Update the document with the new address list
          return firestore
            .collection(`Restaurants/${cafe}/users`)
            .doc(phone)
            .update({
              addressList: updatedAddressList,
              updatedAt: new Date(),
            });
        } else {
          throw new Error("Document not found.");
        }
      })
      .then(() => {
        fetchAllAddresses(); // Refresh the list
        setError(null);
      })
      .catch((err) => {
        setError("Adres silerken hata oluştu: " + err.message);
        console.error("Error deleting address:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Function to handle edit button click
  const handleEdit = (address) => {
    setEditingId(address.id);
    setEditForm({
      title: address.title,
      address: address.address,
    });
  };

  // Function to save the edited address
  const handleSave = (phone) => {
    setLoading(true);

    firestore
      .collection(`Restaurants/${cafe}/users`)
      .doc(phone)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          let addressList = userData.addressList || [];

          // Find the index of the address being edited
          const index = addressList.findIndex(
            (addr) => addr.title === editForm.title
          );

          if (index !== -1) {
            // Update the address at the found index
            addressList[index] = editForm;
          } else {
            throw new Error("Address not found.");
          }

          // Update the document with the modified addressList
          return firestore
            .collection(`Restaurants/${cafe}/users`)
            .doc(phone)
            .update({
              addressList: addressList,
              updatedAt: new Date(),
            });
        } else {
          throw new Error("Document not found.");
        }
      })
      .then(() => {
        fetchAllAddresses(); // Refresh the list
        setEditingId(null);
        setError(null);
      })
      .catch((err) => {
        setError("Adresi güncellerken hata oluştu: " + err.message);
        console.error("Error updating address:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAllAddresses();
  }, [cafe]);

  const goBack = () => {
    history.push("/admin");
  };

  return (
    <div>
      <div className="btn btn-dark mt-3" onClick={goBack}>
        <i
          className="fa fa-arrow-left"
          aria-hidden="true"
          style={{ width: "2vw" }}
        ></i>
      </div>

      <div className="container py-4">
        <div className="row mb-4">
          <div className="col d-flex justify-content-between align-items-center">
            <h1 className="h3">Adres Yönetimi</h1>
            <button
              onClick={() => setShowAddModal(true)}
              className="btn btn-primary"
            >
              <i className="bi bi-plus-circle me-2"></i>
              Yeni Adres Ekle
            </button>
          </div>
        </div>

        {/* Search Section */}
        <div className="row mb-4">
          <div className="col">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
                placeholder="Telefon numarasına göre ara..."
              />
              <button
                className="btn btn-outline-secondary"
                onClick={handleSearch}
              >
                Ara
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={fetchAllAddresses}
              >
                Tümünü Göster
              </button>
            </div>
          </div>
        </div>

        {/* Loading State */}
        {loading && (
          <div className="text-center py-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {/* Address List */}
        <div className="row">
          {addresses.length === 0 && !loading ? (
            <div className="col text-center py-4 text-muted">
              Adres bulunamadı
            </div>
          ) : (
            addresses.map((address) => (
              <div
                key={`${address.id}-${address.title}`}
                className="col-12 mb-3"
              >
                <div className="card">
                  <div className="card-body">
                    {editingId === `${address.id}-${address.title}` ? (
                      // Edit Form for specific address
                      <div>
                        <div className="mb-3">
                          <label className="form-label">Başlık</label>
                          <input
                            type="text"
                            className="form-control"
                            value={editForm.title}
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                title: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Adres</label>
                          <textarea
                            className="form-control"
                            value={editForm.address}
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                address: e.target.value,
                              })
                            }
                            rows="3"
                          />
                        </div>
                        <div className="d-flex gap-2">
                          <button
                            onClick={() => handleSave(address.id)}
                            className="btn btn-success btn-sm"
                          >
                            <i className="bi bi-check-lg me-1"></i>
                            Kaydet
                          </button>
                          <button
                            onClick={() => setEditingId(null)}
                            className="btn btn-secondary btn-sm"
                          >
                            <i className="bi bi-x-lg me-1"></i>
                            İptal
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Display View
                      <div>
                        <div className="d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="card-title">{address.title}</h5>
                            <p className="card-text text-muted small">
                              📞 {address.id}
                            </p>
                            <p className="card-text">{address.address}</p>
                          </div>
                          <div className="btn-group">
                            <button
                              onClick={() =>
                                handleEdit({
                                  ...address,
                                  id: `${address.id}-${address.title}`,
                                })
                              }
                              className="btn btn-outline-primary btn-sm"
                            >
                              <i className="bi bi-pencil me-1"></i>
                              Düzenle
                            </button>
                            <button
                              onClick={() =>
                                handleDelete(address.id, address.title)
                              }
                              className="btn btn-outline-danger btn-sm"
                            >
                              <i className="bi bi-trash me-1"></i>
                              Sil
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Add Address Modal */}
      {showAddModal && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)", paddingTop: "unset" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Yeni Adres Ekle</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowAddModal(false);
                    setNewAddress({ phone: "", title: "", address: "" });
                    setError(null);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <form>
                  <div className="mb-3">
                    <label className="form-label">Telefon Numarası</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={newAddress.phone}
                      onChange={(e) =>
                        setNewAddress({ ...newAddress, phone: e.target.value })
                      }
                      placeholder="Telefon numarası girin"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Başlık</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newAddress.title}
                      onChange={(e) =>
                        setNewAddress({ ...newAddress, title: e.target.value })
                      }
                      placeholder="Ev, İş, vb."
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Adres</label>
                    <textarea
                      className="form-control"
                      value={newAddress.address}
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          address: e.target.value,
                        })
                      }
                      placeholder="Tam adresi girin"
                      rows="4"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowAddModal(false);
                    setNewAddress({ phone: "", title: "", address: "" });
                    setError(null);
                  }}
                >
                  İptal
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddAddress}
                >
                  Adresi Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressList;
